import {
  authInstance,
  userInstance,
  adminInstance,
  transactionInstance,
  gameInstance,
  brandInstance,
  offerInstance,
  notificationInstance,
} from "../config/axios";

export const getAllUsers = async (setLiveUsers, payload, roles) => {
  try {
    const queryMySearchString = payload.name ? `&nickName=${payload.name}` : ``;
    const queryRole =
      roles?.length > 1
        ? `&role=${roles[0]}&role=${roles[1]}`
        : roles?.length === 1
        ? `&role=${roles[0]}`
        : "&role=user&role=distributor";
    const sortBy = "&sortBy=-createdAt";
    const res = await userInstance().get(
      `/getUsers?limit=${payload.limit}&page=${payload.activeindex}${queryRole}${queryMySearchString}${sortBy}`
    );
    setLiveUsers(res.data);
    return res.data;
  } catch (error) {
    console.log("Error in getAllUsers api =>", error);
    return error.response.data;
  }
};

export const getAllContacts = async (setLiveUsers, payload) => {
  try {
    //console.log("payload.status--", payload.status);
    const queryMySearchString = payload.name ? `&name=${payload.name}` : ``;
    const queryMyStatus = payload.status ? `&status=${payload.status}` : ``;
    const sortBy = "&sortBy=-createdAt";
    const res = await userInstance().get(
      `/getContacts?limit=${payload.limit}&page=${payload.activeindex}${queryMySearchString}${queryMyStatus}${sortBy}`
    );
    // setLiveUsers(res.data.results);
    setLiveUsers(res.data);
    return res.data;
  } catch (error) {
    console.log("Error in getAllUsers api =>", error);
    return error.response.data;
  }
};

export const updateContacts = async (values) => {
  try {
    const res = await userInstance().post(`/update-customer-contact`, values);
    return res.data;
  } catch (error) {
    console.log("Error in updateUser api =>", error);
    return error.response.data;
  }
};

export const updateStatusDistributor = async (values) => {
  try {
    const res = await adminInstance().post(`/updateDistributorStatus`, values);
    return res.data;
  } catch (error) {
    console.log("Error in update Distributor api =>", error);
    return error.response.data;
  }
};
export const updateStatusContacts = async (values) => {
  try {
    const res = await adminInstance().post(`/updateContactStatus`, values);
    return res.data;
  } catch (error) {
    console.log("Error in updateUser api =>", error);
    return error.response.data;
  }
};

export const login = async (values) => {
  try {
    const res = await authInstance().post(`/admin-login`, values);
    return res.data;
  } catch (error) {
    console.log("Error in login api =>", error);
    return error.response.data;
  }
};

export const registerAdmin = async (values) => {
  try {
    const res = await authInstance().post(`/register`, values);
    return res.data;
  } catch (error) {
    console.log("Error in regitser admin api =>", error.response.data);
    return error.response.data;
  }
};

export const verifyEmail = async (token) => {
  try {
    const res = await authInstance().post(`/verify-email?token=${token}`);
    return res.data;
  } catch (error) {
    console.log("Error in singup api =>", error);
    return error.response.data;
  }
};

export const forgetPassword = async (values) => {
  try {
    const res = await authInstance().post(`/forgot-password`, values);
    return res.data;
  } catch (error) {
    console.log("Error in forgetPassword api =>", error);
    return error.response.data;
  }
};

export const resetPassword = async (values, email) => {
  try {
    values.email = email;
    const res = await authInstance().post(`/reset-password`, values);
    return res.data;
  } catch (error) {
    console.log("Error in resetPassword api =>", error);
    return error.response.data;
  }
};

export const getUser = async (cb) => {
  try {
    const res = await userInstance().get(`/`);
    return cb({ success: true, user: res.data?.user });
  } catch (error) {
    console.log("Error in getUser api =>", error);
    return cb({ success: false, data: error.response.data });
  }
};

export const updateUser = async (formData, method) => {
  try {
    const res = await userInstance().post(`/update/${method}`, formData);
    return res;
  } catch (error) {
    console.log("Error in updateUser api =>", error);
    return error.response.data;
  }
};

export const sendVerificationCode = async (email) => {
  try {
    const res = await authInstance().post(`/send-verification-code`, { email });
    return res.data;
  } catch (error) {
    console.log("Error in send-verification-code api =>", error);
    return error.response.data;
  }
};
export const verifyVerificationCode = async (data) => {
  try {
    const res = await authInstance().post(`/verify-verification-code`, data);
    return res.data;
  } catch (error) {
    console.log("Error in verify-verification-code api =>", error);
    return error.response.data;
  }
};
export const getChats = async () => {
  try {
    const res = await adminInstance().get(`/chats`);
    return res;
  } catch (error) {
    console.log("Error in getChats api =>", error);
    return error.response.data;
  }
};
export const getChatByUser = async (userId) => {
  try {
    const res = await adminInstance().get(`/chats/${userId}`);
    return res;
  } catch (error) {
    console.log("Error in getChats api =>", error);
    return error.response.data;
  }
};
export const getAllDistributor = async (setLiveDistributor, payload) => {
  try {
    const queryMySearchString = payload.name
      ? `&companyName=${payload.name}`
      : ``;
    const queryMyStatus = payload.status ? `&status=${payload.status}` : ``;
    const sortBy = `&sortBy=-createdAt`;
    const res = await adminInstance().get(
      `/getDistributor?limit=${payload.limit}&page=${payload.activeindex}${queryMySearchString}${queryMyStatus}${sortBy}`
    );
    console.log("res---", res);
    setLiveDistributor(res.data);
    return res.data;
  } catch (error) {
    console.log("Error in get All Distributors api =>", error);
    return error.response.data;
  }
};

export const getDepositList = async (setLiveDepositList, payload) => {
  try {
    const queryPaymentStatus = payload.paymentStatus
      ? `&payment=${payload.paymentStatus}`
      : ``;
    const queryMySearchString = payload.name
      ? `&companyName=${payload.name}`
      : ``;
    const queryMyStatus = payload.status ? `&status=${payload.status}` : ``;
    const sort = "&sortBy=-createdAt";
    const res = await transactionInstance().get(
      `/deposit?transaction=deposit&limit=${payload.limit}&page=${payload.activeindex}${queryMySearchString}${queryMyStatus}${queryPaymentStatus}${sort}`
    );
    console.log(payload);

    setLiveDepositList(res.data.transaction);
    return res.data;
  } catch (error) {
    console.log("Error in get All Diposit api =>", error);
    return error.response.data;
  }
};
export const updateDepositStatus = async (id, postValue) => {
  try {
    const res = await transactionInstance().patch(`/data/${id}`, postValue);
    return res.data;
  } catch (error) {
    console.log("Error in update status Distributor api =>", error);
    return error.response.data;
  }
};
export const getWithdrawList = async (setLiveWithdrawList, payload) => {
  try {
    const queryPaymentStatus = payload.paymentStatus
      ? `&payment=${payload.paymentStatus}`
      : ``;
    const queryMySearchString = payload.name ? `&nickName=${payload.name}` : ``;
    const queryMyStatus = payload.status ? `&status=${payload.status}` : ``;
    const sortBy = `&sortBy=-createdAt`;

    if (queryMyStatus !== "") {
      const res = await transactionInstance().get(
        `/deposit?transaction=withdraw&limit=${payload.limit}&page=${payload.activeindex}${queryMySearchString}${queryMyStatus}${queryPaymentStatus}${sortBy}`
      );
      setLiveWithdrawList(res.data.transaction);
      return res.data;
    }
  } catch (error) {
    console.log("Error in get All Diposit api =>", error);
    return error.response.data;
  }
};
export const updateWithdrawStatus = async (id, postValue) => {
  try {
    const res = await transactionInstance().patch(`/data/${id}`, postValue);
    return res.data;
  } catch (error) {
    console.log("Error in update status Distributor api =>", error);
  }
};

export const getGameRedeemList = async (setLiveGameRedeemList, payload) => {
  try {
    const queryPaymentStatus = payload.paymentStatus
      ? `&payment=${payload.paymentStatus}`
      : ``;
    const queryMySearchString = payload.name
      ? `&companyName=${payload.name}`
      : ``;
    const queryMyStatus = payload.status ? `&status=${payload.status}` : ``;
    const sortBy = "&sortBy=-createdAt";
    const res = await transactionInstance().get(
      `/deposit?transaction=redeem&limit=${payload.limit}&page=${payload.activeindex}${queryMySearchString}${queryMyStatus}${queryPaymentStatus}${sortBy}`
    );
    setLiveGameRedeemList(res.data.transaction);
    return res.data;
  } catch (error) {
    console.log("Error in get All Diposit api =>", error);
    return error.response.data;
  }
};

export const updateRedeemGameStatus = async (id, postValue) => {
  try {
    const res = await transactionInstance().patch(`/data/${id}`, postValue);
    return res.data;
  } catch (error) {
    console.log("Error in update status Game Redeem api =>", error);
  }
};

export const getGameRechargeList = async (setLiveGameRechargeList, payload) => {
  try {
    const queryPaymentStatus = payload.paymentStatus
      ? `&payment=${payload.paymentStatus}`
      : ``;
    const queryMySearchString = payload.name
      ? `&companyName=${payload.name}`
      : ``;
    const queryMyStatus = payload.status ? `&status=${payload.status}` : ``;
    const sortBy = "&sortBy=-createdAt";
    const res = await transactionInstance().get(
      `/deposit?transaction=recharge&limit=${payload.limit}&page=${payload.activeindex}${queryMySearchString}${queryMyStatus}${queryPaymentStatus}${sortBy}`
    );
    setLiveGameRechargeList(res.data.transaction);
    return res.data;
  } catch (error) {
    console.log("Error in get All Diposit api =>", error);
    return error.response.data;
  }
};

export const updateGameRechargeStatus = async (id, postValue) => {
  try {
    const res = await transactionInstance().patch(`/data/${id}`, postValue);
    return res.data;
  } catch (error) {
    console.log("Error in update status Game Redeem api =>", error);
  }
};

export const getGameLists = async (setLiveGamesList, payload) => {
  try {
    const queryMySearchString = payload.name ? `&name=${payload.name}` : ``;
    const queryMyStatus = payload.status ? `&status=${payload.status}` : ``;
    const sortBy = "&sortBy=-createdAt";
    const res = await gameInstance().get(
      `?limit=${payload.limit}&page=${payload.activeindex}${queryMySearchString}${queryMyStatus}${sortBy}`
    );
    setLiveGamesList(res.data.game);
    return res.data;
  } catch (error) {
    console.log("Error in get All Games api =>", error);
    return error.response.data;
  }
};

export const getBrands = async (setLiveBrandList, payload) => {
  try {
    const queryMySearchString = payload.name ? `&name=${payload.name}` : ``;
    const queryMyStatus = payload.status ? `&status=${payload.status}` : ``;
    const sortBy = "&sortBy=-createdAt";
    const res = await brandInstance().get(
      `?limit=${payload.limit}&page=${payload.activeindex}${queryMySearchString}${queryMyStatus}${sortBy}`
    );
    setLiveBrandList(res.data.brands);
    return res.data;
  } catch (error) {
    console.log("Error in get brands api =>", error);
    return error.response.data;
  }
};

export const createGame = async (paylaod) => {
  try {
    const res = await gameInstance().post(`/`, paylaod);
    return res;
  } catch (error) {
    console.log("Error in getChats api =>", error);
    return error.response.data;
  }
};
export const createBrand = async (payload) => {
  try {
    const res = await brandInstance().post(`/`, payload);
    return res;
  } catch (error) {
    console.log("Error in createBrand api =>", error);
    return error.response.data;
  }
};

export const uplaodImage = async (formData) => {
  try {
    const res = await userInstance().post(`/upload-image`, formData);
    console.log(res);
    return res;
  } catch (error) {
    console.log("Error in image uplaod api =>", error);
    return error.response.data;
  }
};

export const getBrandsListOnly = async () => {
  try {
    const res = await gameInstance().get(`/brands`);
    return res;
  } catch (error) {
    console.log("Error in getChats api =>", error);
    return error.response.data;
  }
};

export const fetchGameRequest = async (paginationOptions) => {
  try {
    const { limit, page, status, searchValue, _id } = paginationOptions;
    const statusQuery = `status=${status}`;
    const paginationQuery = `limit=${limit || 10}&page=${page || 1}`;
    const sortBy = `&sortBy=-createdAt`;
    const searchQuery = searchValue ? `&populate=${searchValue},abc` : "";
    const userId = _id ? `&userId=${_id}` : "";
    const res = await gameInstance().get(
      `/game-request?${statusQuery}&${paginationQuery}${sortBy}${searchQuery}${userId}`
    );
    return res;
  } catch (error) {
    console.log("Error in get brands api =>", error);
    return error.response.data;
  }
};

export const updateGameRequest = async (id, updateValue) => {
  try {
    const res = await gameInstance().patch(`/game-request/${id}`, updateValue);
    return res.data;
  } catch (error) {
    console.log("Error in update status Game Redeem api =>", error);
  }
};

export const updateSingleGame = async (_id, formData) => {
  try {
    const res = await gameInstance().patch(`/getGameById/${_id}`, formData);
    return res;
  } catch (error) {
    console.log("Error in get brands api =>", error);
    return error.response.data;
  }
};
export const updateBrand = async (_id, formData) => {
  try {
    const res = await brandInstance().patch(`/brand/${_id}`, formData);
    return res;
  } catch (error) {
    console.log("Error in update brands api =>", error);
    return error.response.data;
  }
};

export const getTotalCoins = async (route, queryData) => {
  try {
    let { status, transaction } = queryData || {};
    route = `${route}-coins`;
    status = `?status=${status}`;
    transaction = `&transaction=${transaction}`;
    const res = await adminInstance().get(`/${route}${status}${transaction}`);
    return res;
  } catch (error) {
    console.log("Error in getChats api =>", error);
    return error.response.data;
  }
};

// Offer APis
export const createOffer = async (payload) => {
  try {
    const res = await offerInstance().post(`/`, payload);
    return res;
  } catch (error) {
    console.log("Error in getChats api =>", error);
    return error.response.data;
  }
};

export const fetchOffers = async (paginationOptions) => {
  try {
    const { limit, page, name, description } = paginationOptions;
    const nameQuery = name ? `&name=${name}` : "";
    const descriptionQuery = description ? `&description=${description}` : "";
    const paginationQuery = `limit=${limit || 10}&page=${page || 1}`;
    const sortBy = `&sortBy=-createdAt`;
    const res = await offerInstance().get(
      `/?${paginationQuery}${sortBy}${nameQuery}${descriptionQuery}`
    );
    return res;
  } catch (error) {
    console.log("Error in get offers api =>", error);
    return error.response.data;
  }
};

export const updateOffer = async (_id, formData) => {
  try {
    const res = await offerInstance().patch(`/${_id}`, formData);
    return res;
  } catch (error) {
    console.log("Error in update offers api =>", error);
    return error.response.data;
  }
};

export const deleteOffer = async (_id) => {
  try {
    const res = await offerInstance().delete(`/${_id}`);
    return res;
  } catch (error) {
    console.log("Error in delete offers api =>", error);
    return error.response.data;
  }
};

export const getNotifications = async () => {
  try {
    const res = await notificationInstance().get();
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const updateNotification = async (notificationIds) => {
  try {
    const res = await notificationInstance().patch("/", notificationIds);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const idleRequestCounts = async () => {
  try {
    const res = await adminInstance().get(`/idle-request`);
    return res;
  } catch (error) {
    console.log("Error in idle req api =>", error);
    return error.response.data;
  }
};

export const getKYCRequest = async (paginationOptions) => {
  try {
    const { limit, page, status, searchValue } = paginationOptions;
    const statusQuery = `status=${status}`;
    const paginationQuery = `limit=${limit || 10}&page=${page || 1}`;
    const sortBy = `&sortBy=-createdAt`;
    const searchQuery = searchValue ? `&populate=${searchValue},abc` : "";
    const res = await adminInstance().get(
      `/kyc?${statusQuery}&${paginationQuery}${sortBy}${searchQuery}`
    );
    return res;
  } catch (error) {
    console.log("Error in get brands api =>", error);
    return error.response.data;
  }
};

export const updatekycRequest = async (id, updateValue) => {
  try {
    const res = await adminInstance().patch(`/kyc/${id}`, updateValue);
    return res.data;
  } catch (error) {
    console.log("Error in update status Game Redeem api =>", error);
  }
};

// player Profile APIs Start---->>

export const getTransactions = async (transactionType, paginationOptions) => {
  try {
    const { limit, page, searchValue, _id } = paginationOptions;
    const statusQuery = `&status=accept&status=reject&status=idle`;
    const transactionQuery = transactionType
      ? `transaction=${transactionType}`
      : "";
    const paginationQuery = `&limit=${limit || 10}&page=${page || 1}`;
    const searchQuery = searchValue ? `&payment=${searchValue}` : "";
    const sortBy = `&sortBy=-createdAt`;
    const userId = _id ? `&userId=${_id}` : "";
    const res = await transactionInstance().get(
      `/${transactionType}?${transactionQuery}${statusQuery}${paginationQuery}${sortBy}${userId}${searchQuery}`
    );
    return res;
  } catch (error) {
    console.log("Error in getChats api =>", error);
    return error.response.data;
  }
};
export const fetchUserIp = async (paginationOptions) => {
  try {
    const { limit, page, _id } = paginationOptions;
    const paginationQuery = `&limit=${limit || 10}&page=${page || 1}`;
    const sortBy = `&sortBy=-createdAt`;
    const userId = _id ? `&userId=${_id}` : "";
    const res = await adminInstance().get(
      `user-ip-address?${paginationQuery}${sortBy}${userId}`
    );
    return res;
  } catch (error) {
    console.log("Error in fetchUserIp api =>", error);
    return error.response.data;
  }
};
export const updateUserIp = async (payload) => {
  try {
    const res = await adminInstance().patch(`user-ip-address`, payload);
    return res;
  } catch (error) {
    console.log("Error in fetchUserIp api =>", error);
    return error.response.data;
  }
};

export const uploadImageSetting = async (paylaod) => {
  try {
    const res = await adminInstance().patch(`settings`, paylaod);
    return res;
  } catch (error) {
    console.log("Error in uploadImageSetting api =>", error);
    return error.response.data;
  }
};
export const getImageSetting = async () => {
  try {
    const res = await adminInstance().get("settings");
    return res;
  } catch (error) {
    console.log("Error in getImageSettings api =>", error);
    return error.response.data;
  }
};

export const updateCoinsUser = async (id, coin) => {
  try {
    const res = await adminInstance().patch(`/update-coins-user/${id}`, {
      coin,
    });
    return res;
  } catch (error) {
    console.log("Error in updateCoinsUser api =>", error);
    return error.response.data;
  }
};

// player Profile APIs End----<<
