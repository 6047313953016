import { useState, useEffect, useCallback } from "react";
import toast from "react-hot-toast";
import { Tabs, Tab, Button } from "react-bootstrap";
import AdminLayout from "../layout/layout";
import SearchBoxComponent from "../search/searchBoxComponent";
import {
  getDepositList,
  getTotalCoins,
  updateDepositStatus,
} from "../../utils/Api";
import usericon1 from "../../assets/images/table/user3.png";
import cashicon from "../../assets/images/table/cash-app.svg";
import cryptoicon from "../../assets/images/table/crypto.svg";
import venmo from "../../assets/images/header/venmo-white.svg";
import chime from "../../assets/images/header/chime1.svg";
import paypal from "../../assets/images/header/paypal1.svg";

import { getDate } from "../../utils/index.js";
// import PagePagination from "../pagination/Pagination";
import coinicon from "../../assets/images/header/coin-icon.svg";
import "./deposit.css";
import PageHeader from "../pageheader/pageheader";
import PagePaginationNew from "../pagination/PaginationNew";
import TableLoader from "../loader/tableloader";
import NoData from "../noData";
import ViewDepositPopup from "./ViewDepositPopup.jsx";

const initialObj = {
  first: 1,
  last: "",
  next: 2,
  prev: 1,
  activeindex: 1,
  skip: 0,
  limit: 10,
  status: "idle",
};

const Deposit = () => {
  const [liveDeposit, setLiveDepositList] = useState([]);
  const [paginationStep, setPaginationStep] = useState({ ...initialObj });
  const [viewStatus, setviewStatus] = useState("idle");
  const [totalData, setTotalData] = useState({});
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const tabsData = [
    {
      title: "all",
      eventKey: "all",
    },
    {
      title: "Cash App",
      eventKey: "cashApp",
    },
    {
      title: "Crypto",
      eventKey: "btc",
    },
    {
      title: "Venmo",
      eventKey: "venmo",
    },
    {
      title: "Chime",
      eventKey: "chime",
    },
    {
      title: "Paypal",
      eventKey: "payPal",
    },
  ];
  const getDeposit = async (paginationObj, step) => {
    setLoading(true);
    const result = await getDepositList(setLiveDepositList, paginationObj);
    setLoading(false);
    if (result && step === "first") {
      let copyObj = { ...paginationObj };
      copyObj.last = result?.totalPages;
      copyObj.status = viewStatus;
      setPaginationStep(copyObj);
    }
  };

  const handlePagenation = (index) => {
    let copyobj = { ...paginationStep };
    copyobj.activeindex = index;
    if (index < paginationStep.last) {
      copyobj.next = index + 1;
    }
    if (index !== 1) {
      copyobj.prev = index - 1;
    }
    copyobj.skip = paginationStep?.limit * (index - 1);
    getDeposit(copyobj);
    setPaginationStep(copyobj);
  };

  const searchDeposit = useCallback(async () => {
    const searchString = paginationStep;
    searchString.name = search;
    // searchString.status = viewStatus;
    searchString.status = "idle";
    setLoading(true);
    await getDepositList(setLiveDepositList, searchString);
    setLoading(false);
    // eslint-disable-next-line
  }, [search]);

  const handleDepositNewTabs = (event) => {
    setviewStatus("idle");
    // { ...paginationStep, status: "idle", paymentStatus: "" },
    console.log({ event });
    // if (event === "cashapp") {
    getDeposit({
      ...initialObj,
      status: "idle",
      paymentStatus: event === "all" ? "" : event,
    });
    setPaginationStep(
      {
        ...initialObj,
        status: "idle",
        paymentStatus: event === "all" ? "" : event,
      },
      "first"
    );

    // else if (event === "crypto") {
    //   getDeposit({ ...initialObj, status: "idle", paymentStatus: "btc" });
    //   setPaginationStep(
    //     { ...initialObj, status: "idle", paymentStatus: "btc" },
    //     "first"
    //   );
    // } else if (event === "all") {
    //   getDeposit({ ...initialObj, status: "idle", paymentStatus: "" });
    //   setPaginationStep(
    //     { ...initialObj, status: "idle", paymentStatus: "" },
    //     "first"
    //   );
    // } else if (event === "venmo") {
    //   getDeposit({ ...initialObj, status: "idle", paymentStatus: "venmo" });
    //   setPaginationStep(
    //     { ...initialObj, status: "idle", paymentStatus: "venmo" },
    //     "first"
    //   );
    // }
  };

  useEffect(() => {
    getDeposit(initialObj, "first");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialObj]);

  const handleStatusUpdate = async (updateId, index) => {
    const data = { status: "accept" };
    const updateResponse = await updateDepositStatus(updateId, data);
    if (updateResponse.transaction.status === "accept") {
      const copyDepost = JSON.parse(JSON.stringify(liveDeposit));
      copyDepost.results.splice(index, 1);
      setLiveDepositList(copyDepost);
      toast.success("DepositedRequest is Accepted!");
    }
  };

  const getTotalCoinsValue = async () => {
    const res = await getTotalCoins("transaction", {
      status: "accept",
      transaction: "deposit",
    });
    if (res.status === 200) {
      setTotalData(res.data.result[0] || {});
    }
  };
  useEffect(() => {
    getTotalCoinsValue();
  }, []);

  return (
    <AdminLayout>
      <div className="deposit-page">
        <PageHeader pageTitle="Deposit Request" totalData={totalData} />
        <div className="container">
          <div className="admin-content-wrapper position-relative">
            <div className="search-wrapper-deposit">
              <SearchBoxComponent
                search={search}
                setSearch={setSearch}
                searchFunction={searchDeposit}
              />
            </div>
            <div className="admin-tab-section">
              <Tabs
                defaultActiveKey={tabsData[0].eventKey}
                id="uncontrolled-tab-example"
                onSelect={(e) => handleDepositNewTabs(e)}
              >
                {tabsData.map((tab) => {
                  return (
                    <Tab eventKey={tab.eventKey} title={tab.title}>
                      <div className="admin-all-deposit">
                        <div className="admin-game-table">
                          <div className="row">
                            <div className="col-md-12">
                              {/* <SearchBoxComponent
                                searchFunction={searchDeposit}
                              /> */}
                              {!loading ? (
                                <>
                                  <div className="overflow-auto">
                                    <div className="admin-custom-table">
                                      {liveDeposit.results &&
                                      liveDeposit.results.length > 0 ? (
                                        liveDeposit.results.map(
                                          (element, index) => {
                                            // return element.payment === "cashApp" ? (
                                            //   <>
                                            //     <AllDepositRow
                                            //       getObjectData={element}
                                            //       handleStatusUpdate={
                                            //         handleStatusUpdate
                                            //       }
                                            //       index={index}
                                            //       key={element?.id ?? element?._id}
                                            //       setLiveDepositList={
                                            //         setLiveDepositList
                                            //       }
                                            //       liveDeposit={liveDeposit}
                                            //     />
                                            //   </>
                                            // ) : (
                                            //   // <CryptoDepositRow
                                            //   //   getObjectData={element}
                                            //   //   handleStatusUpdate={
                                            //   //     handleStatusUpdate
                                            //   //   }
                                            //   //   index={index}
                                            //   //   key={element?.id ?? element?._id}
                                            //   //   setLiveDepositList={
                                            //   //     setLiveDepositList
                                            //   //   }
                                            //   //   liveDeposit={liveDeposit}
                                            //   // />
                                            //   <CommonRow
                                            //     getObjectData={element}
                                            //     handleStatusUpdate={
                                            //       handleStatusUpdate
                                            //     }
                                            //     index={index}
                                            //     key={element?.id ?? element?._id}
                                            //     setLiveDepositList={
                                            //       setLiveDepositList
                                            //     }
                                            //     liveDeposit={liveDeposit}
                                            //   />
                                            // );
                                            return (
                                              <CommonRow
                                                getObjectData={element}
                                                handleStatusUpdate={
                                                  handleStatusUpdate
                                                }
                                                index={index}
                                                key={
                                                  element?.id ?? element?._id
                                                }
                                                setLiveDepositList={
                                                  setLiveDepositList
                                                }
                                                liveDeposit={liveDeposit}
                                              />
                                            );
                                          }
                                        )
                                      ) : (
                                        <NoData heading="No Deposit Data" />
                                      )}
                                    </div>
                                  </div>
                                  {liveDeposit?.totalPages > 1 && (
                                    <PagePaginationNew
                                      page={liveDeposit?.page}
                                      totalpages={liveDeposit?.totalPages}
                                      handlePagination={handlePagenation}
                                    />
                                  )}
                                </>
                              ) : (
                                <TableLoader />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  );
                })}
                {/* <Tab eventKey="cashapp" title="Cash app">
                  <div className="admin-cash-deposit">
                    <div className="admin-game-table">
                      <div className="row">
                        <div className="col-md-12">
                          <SearchBoxComponent searchFunction={searchDeposit} />
                          {!loading ? (
                            <>
                              <div className="admin-custom-table">
                                {liveDeposit.results &&
                                liveDeposit.results.length > 0 ? (
                                  liveDeposit.results.map((element, index) => {
                                    return (
                                      <>
                                        <CommonRow
                                          getObjectData={element}
                                          handleStatusUpdate={
                                            handleStatusUpdate
                                          }
                                          index={index}
                                          key={element?.id ?? element?._id}
                                          setLiveDepositList={
                                            setLiveDepositList
                                          }
                                          liveDeposit={liveDeposit}
                                        />
                                      </>
                                    );
                                  })
                                ) : (
                                  <NoData heading="No Deposit Data" />
                                )}
                              </div>
                              {liveDeposit?.totalPages > 1 && (
                                <PagePaginationNew
                                  page={liveDeposit?.page}
                                  totalpages={liveDeposit?.totalPages}
                                  handlePagination={handlePagenation}
                                />
                              )}
                            </>
                          ) : (
                            <TableLoader />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="crypto" title="Crypto">
                  <div className="admin-crypto-deposit">
                    <div className="admin-game-table">
                      <div className="row">
                        <div className="col-md-12">
                          <SearchBoxComponent searchFunction={searchDeposit} />
                          {!loading ? (
                            <>
                              <div className="admin-custom-table">
                                {liveDeposit.results &&
                                liveDeposit.results.length > 0 ? (
                                  liveDeposit.results.map((element, index) => {
                                    return (
                                      <>
                                        <CommonRow
                                          getObjectData={element}
                                          handleStatusUpdate={
                                            handleStatusUpdate
                                          }
                                          index={index}
                                          key={element?.id ?? element?._id}
                                          setLiveDepositList={
                                            setLiveDepositList
                                          }
                                          liveDeposit={liveDeposit}
                                        />
                                      </>
                                    );
                                  })
                                ) : (
                                  <NoData heading="No Deposit Data" />
                                )}
                              </div>
                              {liveDeposit?.totalPages > 1 && (
                                <PagePaginationNew
                                  page={liveDeposit?.page}
                                  totalpages={liveDeposit?.totalPages}
                                  handlePagination={handlePagenation}
                                />
                              )}
                            </>
                          ) : (
                            <TableLoader />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="venmo" title="Venmo">
                  <div className="admin-cash-deposit">
                    <div className="admin-game-table">
                      <div className="row">
                        <div className="col-md-12">
                          <SearchBoxComponent searchFunction={searchDeposit} />
                          {!loading ? (
                            <>
                              <div className="admin-custom-table">
                                {liveDeposit.results &&
                                liveDeposit.results.length > 0 ? (
                                  liveDeposit.results.map((element, index) => {
                                    return (
                                      <>
                                        <CommonRow
                                          getObjectData={element}
                                          handleStatusUpdate={
                                            handleStatusUpdate
                                          }
                                          index={index}
                                          key={element?.id ?? element?._id}
                                          setLiveDepositList={
                                            setLiveDepositList
                                          }
                                          liveDeposit={liveDeposit}
                                        />
                                      </>
                                    );
                                  })
                                ) : (
                                  <NoData heading="No Deposit Data" />
                                )}
                              </div>
                              {liveDeposit?.totalPages > 1 && (
                                <PagePaginationNew
                                  page={liveDeposit?.page}
                                  totalpages={liveDeposit?.totalPages}
                                  handlePagination={handlePagenation}
                                />
                              )}
                            </>
                          ) : (
                            <TableLoader />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="chime" title="Chime">
                  <div className="admin-crypto-deposit">
                    <div className="admin-game-table">
                      <div className="row">
                        <div className="col-md-12">
                          <SearchBoxComponent searchFunction={searchDeposit} />
                          {!loading ? (
                            <>
                              <div className="admin-custom-table">
                                {liveDeposit.results &&
                                liveDeposit.results.length > 0 ? (
                                  liveDeposit.results.map((element, index) => {
                                    return (
                                      <>
                                        <CommonRow
                                          getObjectData={element}
                                          handleStatusUpdate={
                                            handleStatusUpdate
                                          }
                                          index={index}
                                          key={element?.id ?? element?._id}
                                          setLiveDepositList={
                                            setLiveDepositList
                                          }
                                          liveDeposit={liveDeposit}
                                        />
                                      </>
                                    );
                                  })
                                ) : (
                                  <NoData heading="No Deposit Data" />
                                )}
                              </div>
                              {liveDeposit?.totalPages > 1 && (
                                <PagePaginationNew
                                  page={liveDeposit?.page}
                                  totalpages={liveDeposit?.totalPages}
                                  handlePagination={handlePagenation}
                                />
                              )}
                            </>
                          ) : (
                            <TableLoader />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab> */}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Deposit;

// const AllDepositRow = ({
//   getObjectData,
//   handleStatusUpdate,
//   index,
//   setLiveDepositList,
//   liveDeposit,
// }) => {
//   const [viewDepositRequest, setViewDepositRequest] = useState(false);
//   const [selectedSpecificDepositRequest, setSelectedDepositRequest] =
//     useState();
//   console.log(viewDepositRequest);

//   const closePopup = () => {
//     setViewDepositRequest(false);
//   };
//   const handleViewRequest = (data, index) => {
//     setSelectedDepositRequest({ data: data, index: index });
//     setViewDepositRequest(true);
//   };
//   return (
//     <div className="admin-custom-row">
//       <div className="user-name-grid">
//         <img src={getObjectData?.userId?.avatar || usericon1} alt="game" />
//         <div className="user-name-box">
//           <h6>{getObjectData.userId.name}</h6>
//           <p>{getObjectData.userId.email}</p>
//         </div>
//       </div>
//       <div className="currency-type">
//         <img src={cashicon} alt="game" />
//       </div>
//       <div className="request-date">
//         <p>Date</p>
//         <h6>{getDate(getObjectData.createdAt)}, 10:00</h6>
//       </div>
//       <div className="transaction-amt-grid">
//         <div className="transaction-amt">
//           <p>{getObjectData.transaction}</p>
//           <h6>${getObjectData.amountInUsd}</h6>
//         </div>
//       </div>
//       <div className="coins-amt-grid">
//         <div className="coins-amt">
//           <p>Coins</p>
//           <h6>
//             <img src={coinicon} alt="coin" /> {getObjectData.coin}
//           </h6>
//         </div>
//       </div>
//       <div className="single-action-btn d-flex gap-2">
//         <Button
//           className="view-button bg-primary"
//           onClick={() => handleViewRequest(getObjectData, index)}
//         >
//           View
//         </Button>
//         <Button
//           className="confirm-btn"
//           onClick={() => handleStatusUpdate(getObjectData?.id, index)}
//         >
//           Confirm
//         </Button>
//       </div>
//       <ViewDepositPopup
//         selectedSpecificDepositRequest={selectedSpecificDepositRequest}
//         viewDepositRequest={viewDepositRequest}
//         closePopup={closePopup}
//         setLiveDepositList={setLiveDepositList}
//         handleStatusUpdate={handleStatusUpdate}
//         index={index}
//         liveDeposit={liveDeposit}
//       />
//     </div>
//   );
// };

// const CashDepositRow = ({
//   getObjectData,
//   handleStatusUpdate,
//   index,
//   setLiveDepositList,
//   liveDeposit,
// }) => {
//   const [viewDepositRequest, setViewDepositRequest] = useState(false);
//   const [selectedSpecificDepositRequest, setSelectedDepositRequest] =
//     useState();
//   const closePopup = () => {
//     setViewDepositRequest(false);
//   };
//   const handleViewRequest = (data, index) => {
//     setSelectedDepositRequest({ data: data, index: index });
//     setViewDepositRequest(true);
//   };
//   return (
//     <div className="admin-custom-row">
//       <div className="user-name-grid">
//         <img src={getObjectData?.userId?.avatar || usericon1} alt="game" />
//         <div className="user-name-box">
//           <h6>{getObjectData.userId.name}</h6>
//           <p>{getObjectData.userId.email}</p>
//         </div>
//       </div>
//       <div className="currency-type">
//         <img src={cashicon} alt="game" />
//       </div>
//       <div className="request-date">
//         <p>Date</p>
//         <h6>{getDate(getObjectData.createdAt)}, 10:00</h6>
//       </div>
//       <div className="transaction-amt-grid">
//         <div className="transaction-amt">
//           <p>Deposit</p>
//           <h6>${getObjectData.amountInUsd}</h6>
//         </div>
//       </div>
//       <div className="coins-amt-grid">
//         <div className="coins-amt">
//           <p>Coins</p>
//           <h6>
//             <img src={coinicon} alt="coin" /> {getObjectData.coin}
//           </h6>
//         </div>
//       </div>
//       <div className="single-action-btn d-flex gap-2">
//         <Button
//           className="view-button bg-primary"
//           onClick={() => handleViewRequest(getObjectData, index)}
//         >
//           View
//         </Button>
//         <Button
//           className="confirm-btn"
//           onClick={() => handleStatusUpdate(getObjectData?.id, index)}
//         >
//           Confirm
//         </Button>
//       </div>
//       <ViewDepositPopup
//         selectedSpecificDepositRequest={selectedSpecificDepositRequest}
//         viewDepositRequest={viewDepositRequest}
//         closePopup={closePopup}
//         setLiveDepositList={setLiveDepositList}
//         handleStatusUpdate={handleStatusUpdate}
//         index={index}
//         liveDeposit={liveDeposit}
//       />
//     </div>
//   );
// };

// const CryptoDepositRow = ({
//   getObjectData,
//   handleStatusUpdate,
//   index,
//   setLiveDepositList,
//   liveDeposit,
// }) => {
//   const [viewDepositRequest, setViewDepositRequest] = useState(false);
//   const [selectedSpecificDepositRequest, setSelectedDepositRequest] =
//     useState();
//   const closePopup = () => {
//     console.log("first");
//     setViewDepositRequest(false);
//   };
//   const handleViewRequest = (data, index) => {
//     setSelectedDepositRequest({ data: data, index: index });
//     setViewDepositRequest(true);
//   };
//   return (
//     <div className="admin-custom-row">
//       <div className="user-name-grid">
//         <img src={getObjectData?.userId?.avatar || usericon1} alt="game" />
//         <div className="user-name-box">
//           <h6>{getObjectData?.userId?.nickName}</h6>
//           <p>{getObjectData?.userId.email}</p>
//         </div>
//       </div>
//       <div className="currency-type">
//         <img src={cryptoicon} alt="game" />
//       </div>
//       <div className="request-date">
//         <p>Date</p>
//         <h6>{getDate(getObjectData?.createdAt)}, 10:00</h6>
//       </div>
//       <div className="transaction-amt-grid">
//         <div className="transaction-amt">
//           <p>Deposit</p>
//           <h6>BTC {getObjectData?.cryptoPrice}</h6>
//         </div>
//       </div>
//       <div className="coins-amt-grid">
//         <div className="coins-amt">
//           <p>Coins</p>
//           <h6>
//             <img src={coinicon} alt="coin" /> {getObjectData?.coin}
//           </h6>
//         </div>
//       </div>
//       <div className="single-action-btn d-flex gap-2">
//         <Button
//           className="view-button bg-primary"
//           onClick={() => handleViewRequest(getObjectData, index)}
//         >
//           View
//         </Button>
//         <Button
//           className="confirm-btn"
//           onClick={() => handleStatusUpdate(getObjectData?.id, index)}
//         >
//           Confirm
//         </Button>
//       </div>
//       {viewDepositRequest && (
//         <ViewDepositPopup
//           selectedSpecificDepositRequest={selectedSpecificDepositRequest}
//           viewDepositRequest={viewDepositRequest}
//           closePopup={closePopup}
//           setLiveDepositList={setLiveDepositList}
//           handleStatusUpdate={handleStatusUpdate}
//           index={index}
//           liveDeposit={liveDeposit}
//         />
//       )}
//     </div>
//   );
// };

const CommonRow = ({
  getObjectData,
  handleStatusUpdate,
  index,
  setLiveDepositList,
  liveDeposit,
}) => {
  const [viewDepositRequest, setViewDepositRequest] = useState(false);
  const [selectedSpecificDepositRequest, setSelectedDepositRequest] =
    useState();
  const closePopup = () => {
    console.log("first");
    setViewDepositRequest(false);
  };
  const handleViewRequest = (data, index) => {
    setSelectedDepositRequest({ data: data, index: index });
    setViewDepositRequest(true);
  };
  return (
    <div className="admin-custom-row">
      <div className="user-name-grid">
        <img src={getObjectData?.userId?.avatar || usericon1} alt="game" />
        <div className="user-name-box">
          <h6>{getObjectData?.userId?.nickName}</h6>
          <p>{getObjectData?.userId.email}</p>
        </div>
      </div>
      <div className="currency-type">
        {getObjectData.payment === "btc" ? (
          <img src={cryptoicon} alt="game" />
        ) : getObjectData.payment === "venmo" ? (
          <img src={venmo} alt="game" />
        ) : getObjectData.payment === "paypal" ? (
          <img src={paypal} alt="game" />
        ) : getObjectData.payment === "chime" ? (
          <img src={chime} alt="game" />
        ) : (
          <img src={cashicon} alt="game" />
        )}
      </div>
      <div className="request-date">
        <p>Date</p>
        <h6>{getDate(getObjectData?.createdAt)}, 10:00</h6>
      </div>
      <div className="transaction-amt-grid">
        <div className="transaction-amt">
          <p>Deposit</p>
          {getObjectData?.payment === "btc" ? (
            <h6>BTC {getObjectData?.cryptoPrice}</h6>
          ) : (
            <h6>{getDate(getObjectData.createdAt)}, 10:00</h6>
          )}
        </div>
      </div>
      <div className="coins-amt-grid">
        <div className="coins-amt">
          <p>Coins</p>
          <h6>
            <img src={coinicon} alt="coin" /> {getObjectData?.coin}
          </h6>
        </div>
      </div>
      <div className="single-action-btn d-flex gap-2">
        <Button
          className="view-button bg-primary"
          onClick={() => handleViewRequest(getObjectData, index)}
        >
          View
        </Button>
        <Button
          className="confirm-btn"
          onClick={() => handleStatusUpdate(getObjectData?.id, index)}
        >
          Confirm
        </Button>
      </div>
      {viewDepositRequest && (
        <ViewDepositPopup
          selectedSpecificDepositRequest={selectedSpecificDepositRequest}
          viewDepositRequest={viewDepositRequest}
          closePopup={closePopup}
          setLiveDepositList={setLiveDepositList}
          handleStatusUpdate={handleStatusUpdate}
          index={index}
          liveDeposit={liveDeposit}
        />
      )}
    </div>
  );
};
