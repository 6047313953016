import * as yup from "yup";
import { isValidUrl } from "../utils";

export const RegisterSchema = yup.object().shape({
  nickName: yup
    .string()
    .required("Nick name is required")
    .min(6, "Nick name should be atleast 6 Char")
    .matches(
      "^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$",
      "Nick name shuld be alphanumeric"
    ),
  email: yup
    .string()
    .email("Please enter valid email")
    .required("Email is required"),
  phoneNumber: yup.string().required("Phone number is required"),
  verifyPassword: yup
    .string()
    .required("Password must match")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(.{0,16}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/,
      "Password must be alpa numeric"
    ),
});

export const LoginSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email("Please enter valid email")
      .required("Email is required"),
    password: yup.string().required("Password is required"),
  })
  .required();

export const ResetPasswordSchema = yup.object({
  confirmPassword: yup
    .string()
    .required("Password must match")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(.{0,16}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/,
      "Password must be alpa numeric"
    ),
});

export const ForgetPasswordSchema = yup.object({
  email: yup
    .string()
    .email("Please enter valid email")
    .required("Email is required"),
});

export const createGameSchema = yup.object({
  name: yup.string().required("Brand name is required"),
  description: yup.string().required("Description is required"),
  downloadUrl: yup
    .string()
    .required("Download Url is required")
    .test(
      "is-valid-url",
      () => `Invalid URL`,
      (value) => isValidUrl(value)
    ),
});

export const createBrandSchema = yup.object({
  name: yup.string().required("Game name is required"),
  description: yup.string().required("Description is required"),
});

export const profileUpdate = yup.object().shape(
  {
    firstName: yup.string(),
    lastName: yup.string(),
    email: yup.string().email("Please enter valid email"),
    phoneNumber: yup
      .string()
      .nullable()
      .notRequired()
      .when("phoneNumber", {
        is: (value) => value?.length,
        then: (rule) => rule.min(6, "Phone number must be at least 6 digits"),
      }),
    password: yup
      .string()
      .nullable()
      .notRequired()
      .when("password", {
        is: (value) => value?.length,
        then: (rule) =>
          rule
            .matches(
              /^(.{0,16}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/,
              "Password must be alpa numeric"
            )
            .min(8)
            .max(20),
      }),
    verifyPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    cashTagId: yup.string(),
    btcAddress: yup.string(),
    paypalId: yup.string(),
    venmoId: yup.string(),
    chimeId: yup.string(),
  },
  [
    ["password", "password"],
    ["phoneNumber", "phoneNumber"],
  ]
);

export const offerSchema = yup.object().shape({
  title: yup
    .string()
    .required("Please enter title")
    .max(100, "Title accept 100 char at max"),
  description: yup
    .string()
    .required("Please enter description")
    .max(100, "Description accept 100 char at max"),
});

export const gameCredentials = yup.object().shape({
  gameUsername: yup.string().required("Please enter username"),
  gamePassword: yup.string().required("Please enter password"),
});
