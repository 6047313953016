import { useContext, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import avtar from "../../assets/images/setting/avtar.svg";
import camera from "../../assets/images/setting/camera.svg";
import tick from "../../assets/images/setting/bluetick.svg";
import qrcode from "../../assets/images/setting/qrcode.svg";
import eye from "../../assets/images/setting/eye.svg";
import "./setting.css";
import UserContext from "../../context/UserContext";
import AdminLayout from "../layout/layout";
import toast from "react-hot-toast";
import { updateUser, uplaodImage } from "../../utils/Api";
import { profileUpdate } from "../../validationSchema/authSchema";
import PhoneInput from "react-phone-input-2";

const Setting = () => {
  const { user, setUser } = useContext(UserContext);
  const [passShowHide, setPassShowHide] = useState(false);
  const [confirmPassShowHide, setConfirmPassShowHide] = useState(false);
  const [showTick, setShowTick] = useState([]);
  const [previewBtcImg, setPreviewBtcImg] = useState([]);
  const [previewCashTagImg, setPreviewCashTagImg] = useState([]);
  const [previewChimeImg, setPreviewChimeImg] = useState([]);
  const [previewVenmoImg, setPreviewVenmoImg] = useState([]);
  const [previewPaypalImg, setPreviewPaypalImg] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  // Image uplaod functions---- Start
  const handlePicUpload = async (e) => {
    const { name } = e.target;
    const formData = new FormData();
    if (e.target.files.length > 0) {
      formData.append(name, e.target.files[0]);
      if (name === "btcAddressImage") {
        setPreviewBtcImg([...e.target.files]);
      }
      if (name === "cashTagImage") {
        setPreviewCashTagImg([...e.target.files]);
      }
      if (name === "chimeImage") {
        setPreviewChimeImg([...e.target.files]);
      }
      if (name === "venmoImage") {
        setPreviewVenmoImg([...e.target.files]);
      }
      if (name === "paypalImage") {
        setPreviewPaypalImg([...e.target.files]);
      }
      if (name === "avatar") {
        const response = await uplaodImage(formData);
        if (response.status === 200) {
          toast.success("Profile image changed");
          setUser(response.data.userData);
        } else {
          toast.error("Image has not been changed");
        }
      }
    }
  };
  const handleShowTagTick = (e) => {
    setShowTick({
      ...showTick,
      [e.target.name]: e.target.value,
    });
  };
  //End
  const {
    handleSubmit,
    register,
    setValue,
    setError,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(profileUpdate) });

  const handleUpdatePofile = async (values) => {
    const formData = new FormData();
    let payload = { ...values };
    if (previewCashTagImg.length === 1) {
      formData.append("cashTagImage", previewCashTagImg[0]);
    }
    if (previewBtcImg.length === 1) {
      formData.append("btcAddressImage", previewBtcImg[0]);
    }
    if (previewChimeImg.length === 1) {
      formData.append("chimeImage", previewChimeImg[0]);
    }
    if (previewVenmoImg.length === 1) {
      formData.append("venmoImage", previewVenmoImg[0]);
    }
    if (previewPaypalImg.length === 1) {
      formData.append("paypalImage", previewPaypalImg[0]);
    }
    formData.append("formValues", JSON.stringify(payload));
    setLoading(true);
    const res = await updateUser(formData, "formUpdate");
    setLoading(false);
    if (res.status === 200) {
      setUser(res.data);
      setSuccessMsg("Profile updated.");
      setTimeout(() => {
        setSuccessMsg("");
      }, 1500);
    }
  };

  const handleReset = () => {
    reset({
      firstName: user.firstName || "",
      lastName: user.lastName || "",
      email: user.email || "",
      phoneNumber: user.phoneNumber || "",
      password: "",
      verifyPassword: "",
    });
  };

  const handleChange = (e) => {
    setValue("phoneNumber", e);
    setError("phoneNumber", "customError");
  };
  return (
    <AdminLayout>
      <div className="setting-page-content">
        <div className="container">
          <div className="admin-content-wrapper">
            <h1>Settings</h1>
            <div className="setting-page-detail">
              <div className="setting-page-detail-left">
                <div className="setting-profile">
                  <div className="circle">
                    <img
                      src={user?.avatar ? user.avatar : avtar}
                      alt="userImage"
                    />
                  </div>
                  <div className="setting-camera">
                    <div className="upload-image-icon">
                      <input
                        type="file"
                        id="imageUpload"
                        name="avatar"
                        accept=".png, .jpg, .jpeg"
                        onChange={handlePicUpload}
                      />
                      <label htmlFor="imageUpload">
                        <img src={camera} alt="" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="setting-page-detail-right">
                <h2>Personal info</h2>
                <form onSubmit={handleSubmit(handleUpdatePofile)}>
                  <div className="setting-detail-form">
                    <div className="setting-detail-name">
                      <div className="setting-name">
                        <label>First name</label>
                        <input
                          {...register("firstName")}
                          autoComplete="off"
                          type="text"
                          placeholder="Alexander"
                          defaultValue={user?.firstName}
                          className={
                            errors.firstName
                              ? "error-field form-control"
                              : "form-control"
                          }
                        />
                        {errors?.firstName ? (
                          <p className="error-text">
                            {errors.firstName.message}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="setting-name">
                        <label>Last name</label>
                        <input
                          autoComplete="off"
                          type="text"
                          placeholder="Rybak"
                          defaultValue={user?.lastName}
                          className={
                            errors.email
                              ? "error-field form-control"
                              : "form-control"
                          }
                          {...register("lastName")}
                        />
                        {errors?.lastName ? (
                          <p className="error-text">
                            {errors.lastName.message}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="setting-detail-mail">
                      <label>Email</label>
                      <input
                        autoComplete="off"
                        type="text"
                        defaultValue={user?.email}
                        disabled
                        placeholder="alexander@gmail.com"
                        className={
                          errors.email
                            ? "error-field form-control"
                            : "form-control"
                        }
                        {...register("email")}
                      />
                      {errors?.email ? (
                        <p className="error-text">{errors.email.message}</p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="setting-detail-number">
                      <label>Phone number</label>
                      <PhoneInput
                        country={"us"}
                        onChange={(e) => handleChange(e)}
                        inputProps={{
                          name: "phoneNumber",
                        }}
                        value={user?.phoneNumber}
                        className={errors.phoneNumber ? "error-field" : ""}
                      />
                      {errors?.phoneNumber ? (
                        <p className="error-text">
                          {errors.phoneNumber.message}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="setting-payment-info">
                      <h2>Payment info</h2>
                      <p>
                        For the correct operation of the site,fill of at least
                        one payment system info
                      </p>
                      <div className="setting-payment">
                        <div className="setting-payment-left">
                          <label>Cash App cash-tag</label>
                          <div className="setting-input">
                            <input
                              autoComplete="off"
                              type="text"
                              name="cashTagId"
                              placeholder="#moneyforcasino"
                              defaultValue={user?.cashTagId}
                              className={
                                errors.cashTagId
                                  ? "error-field form-control"
                                  : "form-control"
                              }
                              {...register("cashTagId", {
                                required: true,
                                maxLength: 80,
                              })}
                              onChange={(e) => {
                                handleShowTagTick(e);
                                // setShowTagTick(e.target.value);
                              }}
                            />
                            {errors?.cashTagId ? (
                              <p className="error-text">
                                {errors.cashTagId.message}
                              </p>
                            ) : (
                              ""
                            )}
                            {showTick && showTick["cashTagId"]?.length > 0 && (
                              <>
                                <img src={tick} alt="" />
                              </>
                            )}
                          </div>
                        </div>
                        <div className="setting-payment-right">
                          <label>Cash App QR code</label>
                          <div className="setting-qr-code-input">
                            <input
                              type="file"
                              id="uploadImage"
                              name="cashTagImage"
                              onChange={(e) => handlePicUpload(e)}
                              accept=".png, .jpg, .jpeg"
                              className="form-control"
                            />
                          </div>
                          <div className="setting-qr-code-label">
                            <label htmlFor="uploadImage">
                              <div className="setting-qr-code">
                                <div className="qr-code-scan">
                                  {previewCashTagImg?.length > 0 ? (
                                    <img
                                      src={window.URL.createObjectURL(
                                        previewCashTagImg[0] || ""
                                      )}
                                      alt="btc-preview"
                                      height="37px"
                                      width="37px"
                                    />
                                  ) : (
                                    <img
                                      src={user?.cashTagImage || qrcode}
                                      alt="qr-img"
                                    />
                                  )}
                                </div>
                                <p>
                                  {" "}
                                  {previewCashTagImg?.length > 0 ? (
                                    <span>
                                      {previewCashTagImg[0]?.name.substring(
                                        0,
                                        20
                                      ) + "..."}
                                    </span>
                                  ) : (
                                    <>
                                      <span>Upload QR code</span> (png, jpg)
                                    </>
                                  )}
                                </p>
                              </div>
                            </label>
                          </div>
                          {errors?.cashTagImage ? (
                            <p className="error-text">
                              {errors?.cashTagImage?.message}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="setting-payment">
                        <div className="setting-payment-left">
                          <label>Bitcoin Wallet</label>
                          <input
                            type="string"
                            name="btcAddress"
                            placeholder="n3sZ7yHuQm78i6MiKZDRs"
                            defaultValue={user?.btcAddress}
                            className={
                              errors.btcAddress
                                ? "error-field form-control"
                                : "form-control"
                            }
                            autoComplete="off"
                            {...register("btcAddress")}
                          />
                          {errors?.btcAddress ? (
                            <p className="error-text">
                              {errors.btcAddress.message}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="setting-payment-right">
                          <label>Bitcoin Wallet QR code</label>
                          <div className="setting-qr-code-input">
                            <input
                              type="file"
                              id="btcUpload"
                              name="btcAddressImage"
                              onChange={(e) => handlePicUpload(e)}
                              accept=".png, .jpg, .jpeg"
                              className="form-control"
                            />
                          </div>
                          <div className="setting-qr-code-label">
                            <label htmlFor="btcUpload">
                              <div className="setting-qr-code">
                                <div className="qr-code-scan">
                                  {previewBtcImg?.length > 0 ? (
                                    <img
                                      src={window.URL.createObjectURL(
                                        previewBtcImg[0]
                                      )}
                                      alt="btc-preview"
                                      height="37px"
                                      width="37px"
                                    />
                                  ) : (
                                    <>
                                      {user?.btcAddressImage ? (
                                        <img
                                          src={user?.btcAddressImage}
                                          alt="btc-preview"
                                          height="37px"
                                          width="37px"
                                        />
                                      ) : (
                                        "QR"
                                      )}
                                    </>
                                  )}
                                </div>
                                <p>
                                  {" "}
                                  <p>
                                    {" "}
                                    {previewBtcImg.length > 0 ? (
                                      <span>
                                        {previewBtcImg[0].name.substring(
                                          0,
                                          20
                                        ) + "..."}
                                      </span>
                                    ) : (
                                      <>
                                        <span>Upload QR code</span> (png, jpg)
                                      </>
                                    )}
                                  </p>
                                </p>
                              </div>
                            </label>
                          </div>
                          {errors?.btcAddressImage ? (
                            <p className="error-text">
                              {errors?.btcAddressImage?.message}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="setting-payment">
                        <div className="setting-payment-left">
                          <label>Chime</label>
                          <div className="setting-input">
                            <input
                              autoComplete="off"
                              type="text"
                              name="chimeId"
                              placeholder="#moneyforcasino"
                              defaultValue={user?.chimeId}
                              className={
                                errors?.chimeId
                                  ? "error-field form-control"
                                  : "form-control"
                              }
                              {...register("chimeId")}
                              onChange={(e) => {
                                // setShowTagTick(e.target.value);
                                handleShowTagTick(e);
                              }}
                            />
                            {errors?.chimeId ? (
                              <p className="error-text">
                                {errors.chimeId.message}
                              </p>
                            ) : (
                              ""
                            )}
                            {showTick && showTick["chimeId"]?.length > 0 && (
                              <>
                                <img src={tick} alt="" />
                              </>
                            )}
                          </div>
                        </div>
                        <div className="setting-payment-right">
                          <label>Chime QR code</label>
                          <div className="setting-qr-code-input">
                            <input
                              type="file"
                              id="chimeUpload"
                              name="chimeImage"
                              onChange={(e) => handlePicUpload(e)}
                              accept=".png, .jpg, .jpeg"
                              className="form-control"
                            />
                          </div>
                          <div className="setting-qr-code-label">
                            <label htmlFor="chimeUpload">
                              <div className="setting-qr-code">
                                <div className="qr-code-scan">
                                  {previewChimeImg?.length > 0 ? (
                                    <img
                                      src={window.URL.createObjectURL(
                                        previewChimeImg[0] || ""
                                      )}
                                      alt="btc-preview"
                                      height="37px"
                                      width="37px"
                                    />
                                  ) : (
                                    <img
                                      src={user?.chimeImage || qrcode}
                                      alt="qr-img"
                                    />
                                  )}
                                </div>
                                <p>
                                  {previewChimeImg?.length > 0 ? (
                                    <span>
                                      {previewChimeImg[0]?.name.substring(
                                        0,
                                        20
                                      ) + "..."}
                                    </span>
                                  ) : (
                                    <>
                                      <span>Upload QR code</span> (png, jpg)
                                    </>
                                  )}
                                </p>
                              </div>
                            </label>
                          </div>
                          {errors?.chimeImage ? (
                            <p className="error-text">
                              {errors?.chimeImage?.message}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="setting-payment">
                        <div className="setting-payment-left">
                          <label>Venmo</label>
                          <div className="setting-input">
                            <input
                              autoComplete="off"
                              type="text"
                              name="venmoId"
                              placeholder="#moneyforcasino"
                              defaultValue={user?.venmoId}
                              className={
                                errors?.venmoId
                                  ? "error-field form-control"
                                  : "form-control"
                              }
                              {...register("venmoId")}
                              onChange={(e) => {
                                // setShowTagTick(e.target.value);
                                handleShowTagTick(e);
                              }}
                            />
                            {errors?.venmoId ? (
                              <p className="error-text">
                                {errors.venmoId.message}
                              </p>
                            ) : (
                              ""
                            )}
                            {showTick && showTick["venmoId"]?.length > 0 && (
                              <>
                                <img src={tick} alt="" />
                              </>
                            )}
                          </div>
                        </div>
                        <div className="setting-payment-right">
                          <label>Venmo QR code</label>
                          <div className="setting-qr-code-input">
                            <input
                              type="file"
                              id="venmoUpload"
                              name="venmoImage"
                              onChange={(e) => handlePicUpload(e)}
                              accept=".png, .jpg, .jpeg"
                              className="form-control"
                            />
                          </div>
                          <div className="setting-qr-code-label">
                            <label htmlFor="venmoUpload">
                              <div className="setting-qr-code">
                                <div className="qr-code-scan">
                                  {previewVenmoImg?.length > 0 ? (
                                    <img
                                      src={window.URL.createObjectURL(
                                        previewVenmoImg[0] || ""
                                      )}
                                      alt="btc-preview"
                                      height="37px"
                                      width="37px"
                                    />
                                  ) : (
                                    <img
                                      src={user?.venmoImage || qrcode}
                                      alt="qr-img"
                                    />
                                  )}
                                </div>
                                <p>
                                  {previewVenmoImg?.length > 0 ? (
                                    <span>
                                      {previewVenmoImg[0]?.name.substring(
                                        0,
                                        20
                                      ) + "..."}
                                    </span>
                                  ) : (
                                    <>
                                      <span>Upload QR code</span> (png, jpg)
                                    </>
                                  )}
                                </p>
                              </div>
                            </label>
                          </div>
                          {errors?.venmoImage ? (
                            <p className="error-text">
                              {errors?.venmoImage?.message}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="setting-payment">
                        <div className="setting-payment-left">
                          <label>Paypal</label>
                          <div className="setting-input">
                            <input
                              autoComplete="off"
                              type="text"
                              name="paypalId"
                              placeholder="#moneyforcasino"
                              defaultValue={user?.paypalId}
                              className={
                                errors?.paypalId
                                  ? "error-field form-control"
                                  : "form-control"
                              }
                              {...register("paypalId")}
                              onChange={(e) => {
                                // setShowTagTick(e.target.value);
                                handleShowTagTick(e);
                              }}
                            />
                            {errors?.paypalId ? (
                              <p className="error-text">
                                {errors.paypalId.message}
                              </p>
                            ) : (
                              ""
                            )}
                            {showTick && showTick["paypalId"]?.length > 0 && (
                              <>
                                <img src={tick} alt="" />
                              </>
                            )}
                          </div>
                        </div>
                        <div className="setting-payment-right">
                          <label>Paypal QR code</label>
                          <div className="setting-qr-code-input">
                            <input
                              type="file"
                              id="paypalUpload"
                              name="paypalImage"
                              onChange={(e) => handlePicUpload(e)}
                              accept=".png, .jpg, .jpeg"
                              className="form-control"
                            />
                          </div>
                          <div className="setting-qr-code-label">
                            <label htmlFor="paypalUpload">
                              <div className="setting-qr-code">
                                <div className="qr-code-scan">
                                  {previewPaypalImg?.length > 0 ? (
                                    <img
                                      src={window.URL.createObjectURL(
                                        previewPaypalImg[0] || ""
                                      )}
                                      alt="btc-preview"
                                      height="37px"
                                      width="37px"
                                    />
                                  ) : (
                                    <img
                                      src={user?.paypalImage || qrcode}
                                      alt="qr-img"
                                    />
                                  )}
                                </div>
                                <p>
                                  {previewPaypalImg?.length > 0 ? (
                                    <span>
                                      {previewPaypalImg[0]?.name.substring(
                                        0,
                                        20
                                      ) + "..."}
                                    </span>
                                  ) : (
                                    <>
                                      <span>Upload QR code</span> (png, jpg)
                                    </>
                                  )}
                                </p>
                              </div>
                            </label>
                          </div>
                          {errors?.paypalImage ? (
                            <p className="error-text">
                              {errors?.paypalImage?.message}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="setting-password-info">
                      <h2>Change password</h2>
                      <div className="setting-password">
                        <label>Change Password</label>
                        <div className="setting-input">
                          <input
                            autoComplete="off"
                            type={passShowHide ? "text" : "password"}
                            placeholder="*******************"
                            className={
                              errors.password
                                ? "error-field form-control"
                                : "form-control"
                            }
                            {...register("password")}
                          />
                          {errors?.password ? (
                            <p className="error-text">
                              {errors.password.message}
                            </p>
                          ) : (
                            ""
                          )}
                          <img
                            src={eye}
                            alt=""
                            onClick={() => setPassShowHide(!passShowHide)}
                          />
                        </div>
                      </div>
                      <div className="setting-password">
                        <label>Confirm Password</label>
                        <div className="setting-input">
                          <input
                            autoComplete="off"
                            type={confirmPassShowHide ? "text" : "password"}
                            placeholder="*******************"
                            className={
                              errors.verifyPassword
                                ? "error-field form-control"
                                : "form-control"
                            }
                            {...register("verifyPassword")}
                          />
                          {errors?.verifyPassword ? (
                            <p className="error-text">
                              {errors.verifyPassword.message}
                            </p>
                          ) : (
                            ""
                          )}
                          <img
                            src={eye}
                            alt=""
                            onClick={() =>
                              setConfirmPassShowHide(!confirmPassShowHide)
                            }
                          />
                        </div>
                      </div>
                      {successMsg && (
                        <p className="text-success text-center">{successMsg}</p>
                      )}
                    </div>
                    <div className="setting-button">
                      <div className="transparent-btn">
                        <Button onClick={() => handleReset()}>Discard</Button>
                      </div>
                      <div className="red-btn">
                        <Button type="sumit">
                          {loading ? (
                            <Spinner animation="border" />
                          ) : (
                            "Save changes"
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Setting;
