import { useState, useEffect, useCallback } from "react";
import AdminLayout from "../layout/layout";
import Select from "react-select";
// import PageHeader from "../pageheader/pageheader";
import { Table, Button, Modal, Form, Spinner } from "react-bootstrap";
import {
  getAllUsers,
  // getTotalCoins,
  registerAdmin,
  updateUser,
} from "../../utils/Api";
import { capitalizeFirstLetter, getDate } from "../../utils/index.js";
import editicon from "../../assets/images/offer/edit-icon.svg";
import user from "../../assets/images/table/user2.png";
import "./clients.css";
import TableLoader from "../loader/tableloader";
import PagePaginationNew from "../pagination/PaginationNew";
import NoData from "../noData";
import SearchBoxComponent from "../search/searchBoxComponent";
import plus from "../../assets/images/wallet/plus-red-icon.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  RegisterSchema,
  profileUpdate,
} from "../../validationSchema/authSchema";
import { toast } from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import deleteicon from "../../assets/images/offer/delete-icon.svg";
import DeletePopup from "../delete-popup/delete-popup";

const SelectStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: 44,
    width: "100%",
    backgroundColor: "transparent",
    border: "1px solid rgb(124 116 142 / 20%) !important",
    borderRadius: "4px",
    color: "#0E0D27",
    boxShadow: "none !important",
    position: "realtive",
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#000 !important" : "#0E0D27",
    padding: 8,
    backgroundColor: "transparent !important",
    poition: "absolute",
  }),
};

const SubAdmins = () => {
  const [liveUsers, setLiveUsers] = useState([]);
  const initialObj = {
    first: 1,
    last: "",
    next: 2,
    prev: 1,
    activeindex: 1,
    skip: 0,
    limit: 10,
  };
  const [paginationStep, setPaginationStep] = useState(initialObj);
  const [loading, setLoading] = useState(false);
  // const [totalData, setTotalData] = useState({});
  const [search, setSearch] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState("");
  const [newAdmin, setnewAdmin] = useState(false);
  const [editValue, setEditValue] = useState(null);

  //console.log('SET LIVE URSES....',liveUsers);
  const getUsers = async (paginationObj, step) => {
    setLoading(true);
    const result = await getAllUsers(setLiveUsers, paginationObj, [
      "cashier",
      "customerService",
    ]);
    setLoading(false);
    if (result && step === "first") {
      let copyObj = { ...paginationObj };
      copyObj.last = result?.totalPages;
      setPaginationStep(copyObj);
    }
  };

  useEffect(() => {
    getUsers(initialObj, "first");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePagenation = (index) => {
    let copyobj = { ...paginationStep };
    copyobj.activeindex = index;
    if (index < paginationStep.last) {
      copyobj.next = index + 1;
    }
    if (index !== 1) {
      copyobj.prev = index - 1;
    }
    copyobj.skip = paginationStep?.limit * (index - 1);
    getUsers(copyobj);
    setPaginationStep(copyobj);
  };
  const searchUser = useCallback(
    async (value) => {
      const searchString = paginationStep;
      searchString.name = value;
      await getAllUsers(setLiveUsers, searchString, [
        "cashier",
        "customerService",
      ]);
    },
    // eslint-disable-next-line
    [search]
  );

  // const getTotalCoinsValue = async () => {
  //   const res = await getTotalCoins("client", {});
  //   if (res.status === 200) {
  //     setTotalData(res.data.result[0] || {});
  //   }
  // };
  // useEffect(() => {
  //   getTotalCoinsValue();
  // }, []);

  const handleNewAdmin = (update) => {
    if (update) {
      getUsers(paginationStep);
    }
    setnewAdmin(!newAdmin);
  };

  const editData = (data) => {
    setEditValue(data);
    handleNewAdmin();
  };

  const handleDeletePopup = (id) => {
    setDeleteId({ id });
    if (deletePopup) {
      setDeletePopup(false);
    } else {
      setDeletePopup(true);
    }
  };

  const handleDelete = async (id) => {
    setDeleteLoading(true);
    const res = await updateUser({ userId: id, isDelete: true }, "sub-admin");
    setDeleteLoading(false);
    if (res.status === 200) {
      getUsers(paginationStep);
      setDeleteSuccess("Account deleted.");
      setTimeout(() => {
        setDeleteSuccess("");
        setDeletePopup(false);
      }, 1500);
    }
  };
  return (
    <AdminLayout>
      <div className="client-page sub-admin-page">
        {/* <PageHeader pageTitle="Sub-Admins" totalData={totalData} /> */}
        <div className="container">
          <div className="admin-content-wrapper">
            <div className="admin-section-title">
              <h3>Sub-Admins</h3>
            </div>
            <div className="new-game-btn-wrapper">
              <SearchBoxComponent
                search={search}
                setSearch={setSearch}
                searchFunction={searchUser}
              />
              <Button onClick={() => handleNewAdmin()} className="new-game-btn">
                <img src={plus} alt="icon" /> Add New Admin
              </Button>
            </div>
            {!loading ? (
              <>
                {liveUsers?.results?.length > 0 ? (
                  <>
                    <div className="admin-table">
                      <div className="row">
                        <div className="col-md-12">
                          <Table hover responsive>
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Registration date</th>
                                <th>Role</th>
                                <th>Edit Admin</th>
                                <th>Delete Admin</th>
                              </tr>
                            </thead>
                            <tbody>
                              {liveUsers.results &&
                                liveUsers.results.length > 0 &&
                                liveUsers.results.map((element) => (
                                  <tr key={element?.id ?? element?._id}>
                                    <td>
                                      <div className="user-name">
                                        <img
                                          src={element?.avatar || user}
                                          alt=""
                                        />{" "}
                                        {element?.nickName}
                                      </div>
                                    </td>
                                    <td>{element?.email}</td>
                                    <td>{getDate(element?.createdAt)}</td>
                                    <td>
                                      {capitalizeFirstLetter(element?.role) ||
                                        ""}
                                    </td>
                                    <td>
                                      <img
                                        src={editicon}
                                        alt="arrow"
                                        onClick={() => editData(element)}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </td>
                                    <td>
                                      <img
                                        src={deleteicon}
                                        alt="arrow"
                                        onClick={() =>
                                          handleDeletePopup(element?.id)
                                        }
                                        style={{ cursor: "pointer" }}
                                      />
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                          {liveUsers?.totalPages > 1 && (
                            <PagePaginationNew
                              page={paginationStep?.activeindex}
                              totalpages={liveUsers?.totalPages}
                              handlePagination={handlePagenation}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <NoData heading="No SubAdmins Data" />
                )}
              </>
            ) : (
              <TableLoader />
            )}
          </div>
        </div>
        {newAdmin && (
          <AddNewAdmin
            handleNewAdmin={handleNewAdmin}
            newAdmin={newAdmin}
            editValue={editValue}
            setEditValue={setEditValue}
          />
        )}
      </div>
      {deletePopup && (
        <DeletePopup
          deletePopup={deletePopup}
          handleDeletePopup={handleDeletePopup}
          deleteHeadeing="Please confirm if you want to delete this Account"
          deleteSubHeading="After you confirm, this Account will be deleted."
          data={deleteId}
          handleDelete={handleDelete}
          deleteSuccess={deleteSuccess}
          deleteLoading={deleteLoading}
        />
      )}
    </AdminLayout>
  );
};

export default SubAdmins;

const AddNewAdmin = ({ newAdmin, handleNewAdmin, editValue, setEditValue }) => {
  const [pass, setPass] = useState("password");
  const [cpass, setCPass] = useState("password");
  const [checkEmailInput, setCheckEmailInput] = useState("");
  const roleOptions = [
    { value: "cashier", label: "Cashier" },
    { value: "customerService", label: "Customer Service" },
  ];

  const [selectedRole, setSelectedRole] = useState(
    editValue
      ? {
          label: capitalizeFirstLetter(editValue?.role || ""),
          value: editValue?.role,
        }
      : null
  );
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const {
    handleSubmit,
    register,
    setError,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(editValue?.nickName ? profileUpdate : RegisterSchema),
  });

  const saveData = async (value) => {
    let payload = { ...value };
    if (!selectedRole?.value) {
      setError("role", { message: "Please select Role." });
      return;
    }
    payload.role = selectedRole.value;
    payload.byAdmin = true;
    payload.agree = true;
    let res;
    setLoading(true);
    if (!editValue) {
      res = await registerAdmin(payload);
    } else {
      delete payload.agree;
      delete payload.byAdmin;
      payload.userId = editValue.id;
      if (!payload.password && !payload.verifyPassword) {
        delete payload.password;
        delete payload.verifyPassword;
      }
      res = await updateUser(payload, "sub-admin");
    }
    setLoading(false);
    if (res.code === 200 || res.status === 200) {
      setSuccessMsg("Uploaded succesfully.");
      setTimeout(() => {
        handleNewAdmin(true);
        setSuccessMsg("");
        reset();
        setSelectedRole(null);
      }, 1500);
    } else if (res?.code === 400) {
      toast.error(res?.message);
    } else {
      toast.error("Unable to uplaod data. Please try again");
    }
  };

  const closePopup = () => {
    handleNewAdmin();
    setEditValue(null);
  };

  const handleCPass = () => {
    setCPass(!cpass);
  };
  const handlePass = () => {
    setPass(!pass);
  };

  const handleChange = (e) => {
    setValue("phoneNumber", e);
    setError("phoneNumber", "customError");
  };

  return (
    <Modal
      size="lg"
      className="game-request-popup new-admin-popup"
      show={newAdmin}
      onHide={() => closePopup()}
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="new-game-wrapper">
          <Form onSubmit={handleSubmit(saveData)}>
            <div className="game-request-heading">
              <h2>{editValue ? "Edit" : "Add New"} Admin</h2>
            </div>
            <div className="sub-admin-form">
              <div className="admin-form-row">
                <Form.Group className="form-group">
                  <Form.Label>Nickname</Form.Label>
                  <Form.Control
                    type="text"
                    name="nickName"
                    className={errors.nickName ? "error-field" : ""}
                    defaultValue={editValue?.nickName}
                    {...register("nickName")}
                    autoComplete="off"
                  />
                  {errors?.nickName ? (
                    <p className="error-text">{errors.nickName.message}</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>Role</Form.Label>
                  <Select
                    placeholder="Select Role"
                    options={roleOptions}
                    styles={SelectStyles}
                    onChange={setSelectedRole}
                    isMulti={false}
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={
                      editValue && {
                        label: capitalizeFirstLetter(editValue?.role || ""),
                        value: editValue?.gameCategory || "",
                      }
                    }
                  />
                  {errors?.role ? (
                    <p className="error-text">{errors?.role?.message}</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </div>
              <div className="admin-form-row">
                <Form.Group className="form-group">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    className={errors.email ? "error-field" : ""}
                    {...register("email")}
                    onChange={(e) => setCheckEmailInput(e.target.value)}
                    autoComplete="off"
                    defaultValue={editValue?.email}
                  />
                  {checkEmailInput && checkEmailInput.length > 0 && (
                    <>
                      <span className="input-icon">
                        <i class="las la-check"></i>
                      </span>
                    </>
                  )}

                  {errors?.email ? (
                    <p className="error-text">{errors.email.message}</p>
                  ) : (
                    ""
                  )}
                </Form.Group>

                <Form.Group className="form-group setting-detail-number">
                  <Form.Label>Phone number</Form.Label>
                  <PhoneInput
                    country={"us"}
                    onChange={handleChange}
                    inputProps={{
                      name: "phoneNumber",
                    }}
                    value={editValue?.phoneNumber}
                    className={errors.phoneNumber ? "error-field" : ""}
                  />
                  {errors?.phoneNumber ? (
                    <p className="error-text">{errors.phoneNumber.message}</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </div>
              <div className="admin-form-row">
                <Form.Group className="form-group form-group-password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type={`${pass ? `password` : `text`}`}
                    className={errors.password ? "error-field" : ""}
                    name="password"
                    {...register("password")}
                    autoComplete="off"
                  />
                  <span className="input-icon" onClick={handlePass}>
                    <i class={pass ? "las la-eye" : "las la-eye-slash"}></i>
                  </span>
                  {errors?.password ? (
                    <p className="error-text">{errors.password.message}</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <Form.Group className="form-group form-group-password">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type={`${cpass ? `password` : `text`}`}
                    name="verifyPassword"
                    className={errors.verifyPassword ? "error-field" : ""}
                    {...register("verifyPassword")}
                    autoComplete="off"
                  />
                  <span className="input-icon" onClick={handleCPass}>
                    <i class={cpass ? "las la-eye" : "las la-eye-slash"}></i>
                  </span>
                  {errors?.verifyPassword ? (
                    <p className="error-text">
                      {errors.verifyPassword.message}
                    </p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </div>
            </div>

            {successMsg ? <p className="text-success">{successMsg}</p> : ""}
            <div className="popup-footer-btn">
              <Button className="popup-black" onClick={closePopup}>
                Cancel
              </Button>
              <Button type="submit" className="popup-red">
                {!loading ? (
                  editValue ? (
                    "Update"
                  ) : (
                    "Add"
                  )
                ) : (
                  <Spinner animation="border" />
                )}
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};
