import { useState, useEffect, useCallback } from "react";
import toast from "react-hot-toast";
import { Tabs, Tab, Button, Modal } from "react-bootstrap";
import AdminLayout from "../layout/layout";
import SearchBoxComponent from "../search/searchBoxComponent";
import {
  getTotalCoins,
  getWithdrawList,
  updateWithdrawStatus,
} from "../../utils/Api";
import { getDate } from "../../utils/index.js";
import usericon1 from "../../assets/images/table/user3.png";
import cashicon from "../../assets/images/table/cash-app.svg";
import cryptoicon from "../../assets/images/table/crypto.svg";
// import PagePagination from "../pagination/Pagination";
import "./withdraw.css";
import PageHeader from "../pageheader/pageheader";
import WithdrawPopup from "./withdarw-popup";
import DeletePopup from "../delete-popup/delete-popup";
import PagePaginationNew from "../pagination/PaginationNew";
import TableLoader from "../loader/tableloader";
import NoData from "../noData";
import venmo from "../../assets/images/header/venmo-white.svg";
import chime from "../../assets/images/header/chime1.svg";
import paypal from "../../assets/images/header/paypal1.svg";

const initialObj = {
  first: 1,
  last: "",
  next: 2,
  prev: 1,
  activeindex: 1,
  skip: 0,
  limit: 10,
  status: "idle",
};

const Withdraw = () => {
  const [liveWithdraw, setLiveWithdrawList] = useState([]);
  const [paginationStep, setPaginationStep] = useState({ ...initialObj });
  const [viewStatus, setviewStatus] = useState("idle");
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [selectedWithdrawdata, setselectedWithdrawdata] = useState({});
  const [totalData, setTotalData] = useState({});
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleModal = (data) => {
    setselectedWithdrawdata(data);
    setShow(!show);
  };
  const handleDeletePopup = (data) => {
    setselectedWithdrawdata(data);
    setDeletePopup(!deletePopup);
  };
  const tabsData = [
    {
      title: "all",
      eventKey: "all",
    },
    {
      title: "Cash App",
      eventKey: "cashApp",
    },
    {
      title: "Crypto",
      eventKey: "btc",
    },
    {
      title: "Venmo",
      eventKey: "venmo",
    },
    {
      title: "Chime",
      eventKey: "chime",
    },
    {
      title: "Paypal",
      eventKey: "payPal",
    },
  ];

  const getWithdraw = async (paginationObj, step) => {
    //const result = await getWithdrawList(setLiveWithdrawList, paginationObj);
    setLoading(true);
    const result = await getWithdrawList(setLiveWithdrawList, paginationObj);
    setLoading(false);
    if (result && step === "first") {
      let copyObj = { ...paginationObj };
      copyObj.last = result?.totalPages;
      copyObj.status = viewStatus;
      setPaginationStep(copyObj);
    }
  };

  const handlePagenation = (index) => {
    let copyobj = { ...paginationStep };
    copyobj.activeindex = index;
    if (index < paginationStep.last) {
      copyobj.next = index + 1;
    }
    if (index !== 1) {
      copyobj.prev = index - 1;
    }
    copyobj.skip = paginationStep?.limit * (index - 1);
    getWithdraw(copyobj);
    setPaginationStep(copyobj);
  };

  const searchWithdraw = useCallback(
    async (event, value) => {
      const searchString = paginationStep;
      searchString.name = search;
      // searchString.status = viewStatus;
      searchString.status = "idle";
      setLoading(true);
      await getWithdrawList(setLiveWithdrawList, searchString);
      setLoading(false);
    },
    [search, paginationStep]
  );

  const handleWithdrawNewTabs = (event) => {
    setviewStatus("idle");
    // if (event === "cashapp") {
    getWithdraw({
      ...initialObj,
      status: "idle",
      paymentStatus: event === "all" ? "" : event,
    });
    setPaginationStep(
      {
        ...initialObj,
        status: "idle",
        paymentStatus: event === "all" ? "" : event,
      },
      "first"
    );
    setSearch("");
    // }
    // else if (event === "crypto") {
    //   getWithdraw({ ...initialObj, status: "idle", paymentStatus: "btc" });
    //   setPaginationStep(
    //     { ...initialObj, status: "idle", paymentStatus: "btc" },
    //     "first"
    //   );
    // } else if (event === "all") {
    //   getWithdraw({ ...initialObj, status: "idle", paymentStatus: "" });
    //   setPaginationStep(
    //     { ...initialObj, status: "idle", paymentStatus: "" },
    //     "first"
    //   );
    // }
  };

  // useEffect(() => {
  //   getWithdraw(initialObj, "first");
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [initialObj]);

  const handleStatusUpdate = async (updateId) => {
    const data = { status: "accept" };
    const updateResponse = await updateWithdrawStatus(updateId, data);
    if (updateResponse.transaction.status === "accept") {
      //const copyDepost = JSON.parse(JSON.stringify(liveWithdraw));
      //copyDepost.results.splice(index, 1);
      //setLiveWithdrawList(copyDepost);
      getWithdraw(paginationStep, "first");
      toast.success(" Request is Confirm!");
    }
  };

  const handleConfirm = (Id) => {
    handleStatusUpdate(Id);
    setShow(false);
  };

  const handleStatusCancelUpdate = async (updateId) => {
    const data = { status: "reject" };
    setDeleteLoading(true);
    const updateResponse = await updateWithdrawStatus(updateId, data);
    setDeleteLoading(false);
    if (updateResponse.transaction.status === "reject") {
      getWithdraw(paginationStep, "first");
      toast.success("Request is Canceled!");
      setDeletePopup(!deletePopup);
    }
  };

  const handleCancel = (Id) => {
    // handleStatusCancelUpdate(Id);
    setShow(false);
  };

  const handleReject = (Id) => {
    handleStatusCancelUpdate(Id);
  };
  const getTotalCoinsValue = async () => {
    const res = await getTotalCoins("transaction", {
      status: "accept",
      transaction: "withdraw",
    });
    if (res.status === 200) {
      setTotalData(res.data.result[0] || {});
    }
  };
  useEffect(() => {
    getTotalCoinsValue();
  }, []);

  useEffect(() => {
    getWithdraw(initialObj, "first");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialObj]);
  return (
    <AdminLayout>
      <div className="withdraw-page">
        <PageHeader pageTitle="Withdraw Request" totalData={totalData} />
        <div className="container">
          <div className="admin-content-wrapper">
            <div className="admin-tab-section position-relative">
              <div className="search-wrapper-withdraw">
                <SearchBoxComponent
                  search={search}
                  setSearch={setSearch}
                  searchFunction={searchWithdraw}
                />
              </div>
              <Tabs
                defaultActiveKey="all"
                id="uncontrolled-tab-example"
                onSelect={handleWithdrawNewTabs}
              >
                {tabsData.map((tab) => {
                  return (
                    <Tab eventKey={tab.eventKey} title={tab.title}>
                      <div className="admin-all-withdraw">
                        <div className="admin-game-table">
                          <div className="row">
                            <div className="col-md-12">
                              {/* <SearchBoxComponent
                                searchFunction={searchWithdraw}
                              /> */}
                              {!loading ? (
                                <>
                                  <div className="admin-custom-table">
                                    {liveWithdraw.results &&
                                    liveWithdraw.results.length > 0 ? (
                                      liveWithdraw.results.map(
                                        (element, index) => {
                                          // return element.payment === "cashApp" ? (
                                          // <>
                                          return (
                                            <CommonRow
                                              handleModal={handleModal}
                                              handleDeletePopup={
                                                handleDeletePopup
                                              }
                                              getObjectData={element}
                                              handleStatusUpdate={
                                                handleStatusUpdate
                                              }
                                              key={element?.id ?? element?._id}
                                            />
                                          );
                                          // </>
                                          // ) : (
                                          //   <>
                                          //     <CommonRow
                                          //       handleModal={handleModal}
                                          //       handleDeletePopup={handleDeletePopup}
                                          //       getObjectData={element}
                                          //       handleStatusUpdate={
                                          //         handleStatusUpdate
                                          //       }
                                          //       index={index}
                                          //     />
                                          //   </>
                                          // );
                                        }
                                      )
                                    ) : (
                                      <NoData heading="No Withdraw Data" />
                                    )}
                                  </div>
                                  {liveWithdraw?.totalPages > 1 && (
                                    <PagePaginationNew
                                      page={liveWithdraw?.page}
                                      totalpages={liveWithdraw?.totalPages}
                                      handlePagination={handlePagenation}
                                    />
                                  )}
                                </>
                              ) : (
                                <TableLoader />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  );
                })}
                {/* <Tab eventKey="cashapp" title="Cash app">
                  <div className="admin-cash-withdraw">
                    <div className="admin-game-table">
                      <div className="row">
                        <div className="col-md-12">
                          <SearchBoxComponent searchFunction={searchWithdraw} />
                          {!loading ? (
                            <>
                              <div className="admin-custom-table">
                                {liveWithdraw.results &&
                                liveWithdraw.results.length > 0 ? (
                                  liveWithdraw.results.map((element, index) => {
                                    return (
                                      <>
                                        <CommonRow
                                          handleModal={handleModal}
                                          handleDeletePopup={handleDeletePopup}
                                          getObjectData={element}
                                          handleStatusUpdate={
                                            handleStatusUpdate
                                          }
                                          key={element?.id ?? element?._id}
                                        />
                                      </>
                                    );
                                  })
                                ) : (
                                  <NoData heading="No Withdraw Data" />
                                )}
                              </div>
                              {liveWithdraw?.totalPages > 1 && (
                                <PagePaginationNew
                                  page={liveWithdraw?.page}
                                  totalpages={liveWithdraw?.totalPages}
                                  handlePagination={handlePagenation}
                                />
                              )}
                            </>
                          ) : (
                            <TableLoader />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="crypto" title="Crypto">
                  <div className="admin-crypto-withdraw">
                    <div className="admin-game-table">
                      <div className="row">
                        <div className="col-md-12">
                          <SearchBoxComponent />
                          {!loading ? (
                            <>
                              <div className="admin-custom-table">
                                {liveWithdraw.results &&
                                liveWithdraw.results.length > 0 ? (
                                  liveWithdraw.results.map((element, index) => {
                                    return (
                                      <>
                                        <CommonRow
                                          handleModal={handleModal}
                                          handleDeletePopup={handleDeletePopup}
                                          getObjectData={element}
                                          handleStatusUpdate={
                                            handleStatusUpdate
                                          }
                                          key={element?.id ?? element?._id}
                                        />
                                      </>
                                    );
                                  })
                                ) : (
                                  <NoData heading="No Withdraw Data" />
                                )}
                              </div>
                              {liveWithdraw?.totalPages > 1 && (
                                <PagePaginationNew
                                  page={liveWithdraw?.page}
                                  totalpages={liveWithdraw?.totalPages}
                                  handlePagination={handlePagenation}
                                />
                              )}
                            </>
                          ) : (
                            <TableLoader />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab> */}
              </Tabs>
            </div>
          </div>
        </div>
        <Modal
          centered
          className="withdarw-popup"
          show={show}
          size="lg"
          onHide={() => handleModal()}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <WithdrawPopup
              handleModal={handleModal}
              data={selectedWithdrawdata}
              handleConfirm={handleConfirm}
              handleCancel={handleCancel}
            />
          </Modal.Body>
        </Modal>
        {deletePopup && (
          <DeletePopup
            deletePopup={deletePopup}
            handleDeletePopup={handleDeletePopup}
            deleteHeadeing="Please confirm if you want to cancel this Request"
            deleteSubHeading="After you confirm, this Request will be remove."
            data={selectedWithdrawdata}
            handleDelete={handleReject}
            deleteLoading={deleteLoading}
          />
        )}
      </div>
    </AdminLayout>
  );
};

export default Withdraw;

// const AllWitdrawRow = ({
//   handleModal,
//   handleDeletePopup,
//   getObjectData,
//   handleStatusUpdate,
// }) => {
//   return (
//     <div className="admin-custom-row">
//       <div className="user-name-grid">
//         <img src={getObjectData?.userId?.avatar || usericon1} alt="game" />
//         <div className="user-name-box">
//           <h6>{getObjectData.userId.name}</h6>
//           <p>{getObjectData.userId.email}</p>
//         </div>
//       </div>
//       <div className="currency-type">
//         <img src={cashicon} alt="game" />
//       </div>
//       <div className="request-date">
//         <p>Date</p>
//         <h6>{getDate(getObjectData.createdAt)}, 10:00</h6>
//       </div>
//       <div className="transaction-amt-grid">
//         <div className="transaction-amt">
//           <p>{getObjectData.transaction}</p>
//           <h6>${getObjectData.amountInUsd}</h6>
//         </div>
//       </div>
//       <div className="transaction-amt-grid">
//         <div className="transaction-amt">
//           <p>Cash Tag</p>
//           <h6
//             className="text-truncate text-center"
//             style={{ fontSize: "12px" }}
//           >
//             {getObjectData?.cashtag || "-"}
//           </h6>
//         </div>
//       </div>
//       <div className="action-btn">
//         {/* <Button className="cancle-btn" onClick={handleDeletePopup}> */}
//         <Button
//           className="cancle-btn"
//           onClick={() => handleDeletePopup(getObjectData, handleStatusUpdate)}
//         >
//           Cancel
//         </Button>
//         <Button
//           className="confirm-btn"
//           onClick={() => handleModal(getObjectData, handleStatusUpdate)}
//         >
//           Confirm
//         </Button>
//       </div>
//     </div>
//   );
// };

// const CashWitdrawRow = ({
//   handleModal,
//   handleDeletePopup,
//   getObjectData,
//   handleStatusUpdate,
// }) => {
//   return (
//     <div className="admin-custom-row">
//       <div className="user-name-grid">
//         <img src={getObjectData?.userId?.avatar || usericon1} alt="game" />
//         <div className="user-name-box">
//           <h6>{getObjectData.userId.name}</h6>
//           <p>{getObjectData.userId.email}</p>
//         </div>
//       </div>
//       <div className="currency-type">
//         <img src={cashicon} alt="game" />
//       </div>
//       <div className="request-date">
//         <p>Date</p>
//         <h6>{getDate(getObjectData.createdAt)}, 10:00</h6>
//       </div>
//       <div className="transaction-amt-grid">
//         <div className="transaction-amt">
//           <p>{getObjectData.transaction}</p>
//           <h6>${getObjectData.amountInUsd}</h6>
//         </div>
//       </div>
//       <div className="action-btn">
//         <Button
//           className="cancle-btn"
//           onClick={() => handleDeletePopup(getObjectData, handleStatusUpdate)}
//         >
//           Cancel
//         </Button>
//         <Button
//           className="confirm-btn"
//           onClick={() => handleModal(getObjectData, handleStatusUpdate)}
//         >
//           Confirm
//         </Button>
//       </div>
//     </div>
//   );
// };

// const CryptohWitdrawRow = ({
//   handleModal,
//   handleDeletePopup,
//   getObjectData,
//   handleStatusUpdate,
// }) => {
//   return (
//     <div className="admin-custom-row">
//       <div className="user-name-grid">
//         <img src={getObjectData?.userId?.avatar || usericon1} alt="game" />
//         <div className="user-name-box">
//           <h6>{getObjectData?.userId?.nickName}</h6>
//           <p>{getObjectData?.userId.email}</p>
//         </div>
//       </div>
//       <div className="currency-type">
//         <img src={cryptoicon} alt="game" />
//       </div>
//       <div className="request-date">
//         <p>Date</p>
//         <h6>{getDate(getObjectData?.createdAt)}, 10:00</h6>
//       </div>
//       <div className="transaction-amt-grid">
//         <div className="transaction-amt">
//           <h6>BTC {getObjectData?.cryptoPrice}</h6>
//         </div>
//       </div>
//       <div className="transaction-amt-grid">
//         <div className="transaction-amt">
//           <p>Cash Tag</p>
//           <h6
//             className="text-truncate  text-center"
//             style={{ fontSize: "12px" }}
//           >
//             {getObjectData?.cashtag || "-"}
//           </h6>
//         </div>
//       </div>
//       <div className="action-btn">
//         <Button
//           className="cancle-btn"
//           onClick={() => handleDeletePopup(getObjectData, handleStatusUpdate)}
//         >
//           Cancel
//         </Button>
//         <Button
//           className="confirm-btn"
//           onClick={() => handleModal(getObjectData, handleStatusUpdate)}
//           // onClick={() => handleStatusUpdate(getObjectData?.id, index)}
//         >
//           Confirm
//         </Button>
//       </div>
//     </div>
//   );
// };

const CommonRow = ({
  handleModal,
  handleDeletePopup,
  getObjectData,
  handleStatusUpdate,
}) => {
  return (
    <div className="admin-custom-row">
      <div className="user-name-grid">
        <img src={getObjectData?.userId?.avatar || usericon1} alt="game" />
        <div className="user-name-box">
          <h6>{getObjectData?.userId?.nickName}</h6>
          <p>{getObjectData?.userId.email}</p>
        </div>
      </div>
      <div className="currency-type">
        {getObjectData.payment === "btc" ? (
          <img src={cryptoicon} alt="game" />
        ) : getObjectData.payment === "venmo" ? (
          <img src={venmo} alt="game" />
        ) : getObjectData.payment === "paypal" ? (
          <img src={paypal} alt="game" />
        ) : getObjectData.payment === "chime" ? (
          <img src={chime} alt="game" />
        ) : (
          <img src={cashicon} alt="game" />
        )}
      </div>
      <div className="request-date">
        <p>Date</p>
        <h6>{getDate(getObjectData?.createdAt)}, 10:00</h6>
      </div>
      <div className="transaction-amt-grid">
        <div className="transaction-amt">
          {getObjectData.payment === "btc" ? (
            <h6>BTC {getObjectData?.cryptoPrice}</h6>
          ) : (
            <>
              <p>{getObjectData.transaction}</p>
              <h6>${getObjectData.amountInUsd}</h6>
            </>
          )}
        </div>
      </div>
      <div className="transaction-amt-grid">
        <div className="transaction-amt">
          <p>Cash Tag</p>
          <h6
            className="text-truncate  text-center"
            style={{ fontSize: "12px" }}
          >
            {getObjectData?.cashtag || "-"}
          </h6>
        </div>
      </div>
      <div className="action-btn">
        <Button
          className="cancle-btn"
          onClick={() => handleDeletePopup(getObjectData, handleStatusUpdate)}
        >
          Cancel
        </Button>
        <Button
          className="confirm-btn"
          onClick={() => handleModal(getObjectData, handleStatusUpdate)}
          // onClick={() => handleStatusUpdate(getObjectData?.id, index)}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};
