import { useState } from "react";
import { Form, Button, FormControl } from "react-bootstrap";
import useDebounce from "../../hooks/useDebounce";
import "./search.css";

const SearchBoxComponent = ({ search, setSearch, searchFunction }) => {
  // const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  useDebounce(search, searchFunction, 500, count);

  const handleChnage = (e) => {
    if (count === 0) {
      setCount(count + 1);
    }
    setSearch(e.target.value);
    // if (e.target.value.trim() === "") {
    //   searchFunction(e.target.value);
    // }
  };
  console.log(search);
  return (
    <div className="search-wrapper">
      <div className="search-box">
        <Form className="search-box-form">
          <Button>
            <i className="las la-search" />
          </Button>
          <FormControl
            type="search"
            value={search}
            onChange={handleChnage}
            placeholder="Search"
            aria-label="Search"
          />
        </Form>
      </div>
    </div>
  );
};

export default SearchBoxComponent;
